<template>
  <div>
    <OrdersList v-if="$route.path === '/ordens'" />
  </div>
</template>

<script>
export default {
  name: 'OrdersPageList'
}
</script>
